<template>
  <v-container class="mt-5">

    <!--left side-->
    <v-row v-if="products.length">
      <!--  Will Impelment Later    -->
<!--      <v-col cols="12" lg="3" order="2" order-lg="1">-->
<!--        <v-card class="mt-5">-->
<!--          <v-tabs-->
<!--            v-model="tab"-->
<!--            background-color="primaryDark"-->
<!--            dark-->
<!--            grow-->
<!--          >-->
<!--            <v-tabs-slider></v-tabs-slider>-->
<!--            <v-tab href="#tab-1">Popular</v-tab>-->
<!--            <v-tab href="#tab-2">Latest</v-tab>-->
<!--            <v-tab href="#tab-3">Deals</v-tab>-->
<!--          </v-tabs>-->

<!--          <v-tabs-items v-model="tab">-->
<!--            <v-tab-item-->
<!--              v-for="i in 3"-->
<!--              :key="i"-->
<!--              :value="'tab-' + i"-->
<!--            >-->
<!--              <popular-product v-for="i in 3" :key="i"></popular-product>-->
<!--            </v-tab-item>-->
<!--          </v-tabs-items>-->
<!--        </v-card>-->
<!--      </v-col>-->
      <v-col cols="12">
        <product-grid :products="products"></product-grid>
      </v-col>
    </v-row>

    <!--empty product msg-->
    <v-row v-else align-content="center" class="mt-5" justify="center">
      <v-alert border="right" colored-border elevation="2" type="warning">
        <h3 class="title">This vendor has no products available to sell</h3>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import PopularProduct from '@/components/Product/PopularProduct';
import ProductGrid from '@/components/Product/ProductGrid';

import { mapGetters } from 'vuex';

export default {
  name: 'featured-products',
  components: { ProductGrid, PopularProduct },
  data: () => ({
    tab: null,
    selectedSort: {},
  }),
  computed: {
    ...mapGetters({
      products: 'vendor/vendorProducts',
    })
  },
  methods: {
    handleProductAction(e) {
      console.log(e);
    },
    async getVendorData() {
      let slug = this.$route.params.slug;
      await this.$store.dispatch('vendor/getVendorData', slug);
      // let data = await shopData(slug)
      // await this.$store.dispatch(storeHelper(vendorNameSpace, VENDOR_CATEGORIES), data.data.data.categories)
      // await this.$store.dispatch(storeHelper(vendorNameSpace, VENDOR_BRANDS), data.data.data.brands)
      // await this.$store.dispatch(storeHelper(vendorNameSpace, VENDOR_PRODUCTS), data.data.data.products)
      // await this.$store.dispatch(storeHelper(vendorNameSpace, VENDOR_PRICE_RANGE), [data.data.data.price_range.min, data.data.data.price_range.max])
      // this.priceRange = [data.data.data.price_range.min, data.data.data.price_range.max]
      // await this.$store.dispatch(storeHelper(productNamespace, PRODUCT_BASIC_SORT_OPTIONS), sortOption)
    },
  },
  async mounted() {
    await this.getVendorData();
  }
};
</script>

<style scoped>

</style>
