<template>
  <v-card
    class="mx-auto"
    color="white"
  >
    <router-link
      :title="item.name"
      :to="{name: 'ProductSingle',params: { product : item.slug, vendor:item.shop.slug}}"
    >
      <v-img :src="images(item)" aspect-ratio="1" class="cursor-pointer" />
    </router-link>
    <v-card-text class="pt-0">
      <v-card-title class="pa-0 pt-1 product-title">
        <router-link
          :title="item.name"
          :to="{
            name: 'ProductSingle',
            params: {
              product : item.slug,
              vendor:item.shop.slug
            }}"
          class="text--primary clip-text"
        >
          {{ item.name }}
        </router-link>
      </v-card-title>
      <div class="grey--text text--darken-1 clip-text mt-1">
        Sold By
        <router-link
          :to="`/vendor/${item.shop.slug}`"
          class="text-decoration-none secondary--text"
        >
          {{ item.shop.name }}
        </router-link>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex justify-space-between text--white">

      <v-card-subtitle class="text-h6 font-weight-bold pt-0 pl-1 pb-1">
        {{ item.price | currency }}
      </v-card-subtitle>

      <v-btn
        class="mr-1"
        color="secondary"
        @click="handleAction(item, 'cart')"
      >
        <v-icon size="16">mdi-cart</v-icon>
        <span class="hidden-lg-and-down">Add to Cart</span>
      </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import { CART_ADD, cartNameSpace } from '@/store/modules/cart/types';
import { API_BASE_URL, storeHelper } from '@/utils/helper';
import { mapActions } from 'vuex';
import sliderImage from '@/assets/images/Product/product_162_1_thumb.jpg';
export default {
  name: 'product-card',
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  emits: ['action-clicked'],

  data: () => ({
    image: sliderImage,
    defaultImage: `${API_BASE_URL}/storage/no-images.jpg`
  }),

  methods: {
    ...mapActions({
      addToCart: storeHelper(cartNameSpace, CART_ADD),
    }),

    images(item) {
      if (!item.images?.length) return this.defaultImage; // if no images then show Default Images
      return item.images[0].url; // return first image as thumbnail
    },
    handleAction(item, action) {
      let actionName;

      switch (action) {

        case 'view' :
          actionName = 'view';
        break;

        case 'compare' :
          actionName = 'compare';
        break;

        case 'wishlist' :
          actionName = 'wishlist';
        break;

        case 'cart' :
          actionName = 'cart';
          this.addToCart(item);
        break;
      }
      this.$emit('action-clicked', { itemId: item, actionName });
    }
  },
};
</script>

<style lang="scss" scoped>

.product-title{
  word-break: normal;
  font-size: 16px;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  background-color: rgba(orange, .5);
  position: absolute;
  width: 100%;
}

.product-selection {
  cursor: pointer;
}

.v-card__title {
  justify-content: flex-start;
  align-content: flex-start;
}
</style>
