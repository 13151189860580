<template>
  <v-sheet color="lite">
    <v-overlay
      :value="dataLoading"
      absolute
      class="productOverlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row v-if="products">
      <v-col
        v-for="(item, index) in products"
        :key="'grid'+index"
        cols="12"
        lg="3"
        sm="4"
      >
        <ProductCard :item="item" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import ProductCard from '@/components/Product/ProductCard';

export default {
  props: {
    products: {
      type: [Array, Object]
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  name: 'product-grid',
  components: { ProductCard },
  data: () => ({
    dataLoading: false
  }),

  watch: {
    loading: {
      handler(value) {
        this.dataLoading = value;
      },
      immediate: true,
      deep: true
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
